<template>
  <div>
    <v-container fluid px-0>
      <v-row align="center" justify="center" class="mx-0">
        <v-col cols="12">
          <v-text-field
            v-model="pesquisaCards"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            class="Barra_Pesquisa"
            single-line
            hide-details
            clearable>
          </v-text-field>
        </v-col>

        <v-container fluid grid-list-md v-if="listaCards.length">
          <v-data-iterator
            :items="listaCards"
            :options.sync="pagination"
            :hide-default-footer="12 >= totalPage"
            :server-items-length="totalPage"
            :footer-props="{
              itemsPerPageOptions: rowsPerPageItems,
            }">
            <template v-slot:default="props">
              <v-row justify="start">
                <v-col
                  v-for="item in props.items"
                  :key="item.cod_apuracao"
                  cols="12"
                  sm="4"
                  :md="!isVisaoContrato && item.status === 'AGUARDANDO_APURACAO' ? 4 : 3">
                  <v-hover v-slot:default="{ hover }">
                    <v-card :class="`Card_${item.status} elevation-${hover ? 5 : 1}`"
                            class="clickable Card_Apuracao" fill-height>
                      <v-card-title class="Card_Title justify-center text-center" @click="selectItem(item)">
                        <popover-lista :campos="camposPopover" :offset-x="true" :offset-y="true"
                                      :objeto="item" class="ApuracaoContratoNivel2__popover-lista" :nudge-width="300">
                          <template slot="ativadorPopover">
                            <span class="title">{{ getTitulo(item) }}</span><br />
                            <span class="body-2 font-weight-regular">{{ getSubtitulo(item) }}</span>
                          </template>
                        </popover-lista>
                      </v-card-title>
                      <v-card-text class="Card_Content py-0" @click="selectItem(item)">
                        <v-row justify="center" align="center">
                          <p class="headline font-weight-light">
                            {{ getMoney(item.recebimento, 0) }}
                          </p>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-row class="caption text-center py-3" align="end">
                          <v-col :cols="tamanhoCampos(item)" v-if="item.extensaoRecursiva">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-row justify="center" align="center" v-on="on">
                                  <v-col cols="12" sm="12" style="text-align: center;" @click="selectItem(item)">
                                    {{ item.extensaoRecursiva | truncate(30) }} <br />
                                    <v-icon>category</v-icon>
                                  </v-col>
                                </v-row>
                              </template>
                              <span>{{ $t('label.categoria_produto_apuracao') }}</span>
                            </v-tooltip>
                          </v-col>

                          <v-col :cols="tamanhoCampos(item)" v-if="!item.semMeta">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-row justify="center" align="center" v-on="on">
                                  <v-col cols="12" sm="12" style="text-align: center; white-space: nowrap;" @click="selectItem(item)">
                                    <p class="ma-0" v-if="item.metaValor">{{ getMoney(item.realizado) }}</p>
                                    <p class="ma-0" v-else-if="item.metaPercentual">{{ getPercent(item.realizado) }}</p>
                                    <p class="ma-0" v-else>{{ item.realizado }}</p>
                                    <v-icon>adjust</v-icon>
                                  </v-col>
                                </v-row>
                              </template>
                              <span>{{ $t('label.meta_atingida') }}</span>
                            </v-tooltip>
                          </v-col>

                          <v-col :cols="tamanhoCampos(item)">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-row justify="center" align="center" v-on="on">
                                  <v-col cols="12" sm="12" style="text-align: center; white-space: nowrap;">
                                    <p class="ma-0" v-if="dataFimEInicioIgual(item.dataFim, item.dataInicio)" @click="selectItem(item)">{{ converterMesAno(item.dataInicio)}}</p>
                                    <p class="ma-0 nowrap" v-else @click="selectItem(item)">{{ `${converterMesAno(item.dataInicio)} ${$tc('label.a', 2)} ${converterMesAno(item.dataFim)}`}}</p>
                                    <v-icon v-if="!indAlterarDataFimApuracao" @click="selectItem(item)">calendar_today</v-icon>
                                    <apuracao-alterar-data-fim
                                      v-else
                                      :apuracao="item"
                                      @ApuracaoDetalhes__alteradoDataFim
                                      ="data => alterarDataFim(item, data)">
                                    </apuracao-alterar-data-fim>
                                  </v-col>
                                </v-row>
                              </template>
                              <span>{{ $t('label.periodo_apuracao') }}</span>
                            </v-tooltip>
                          </v-col>

                          <v-col :cols="tamanhoCampos(item)" v-if="podeConcluir(item)">
                            <apuracao-contrato-acao-concluir
                              :apuracao="item"
                              :concluir-automaticamente="true"
                              @ApuracaoContratoAcaoConcluir_concluido
                                ="(indAlterarStatus) => alterarStatus(item, indAlterarStatus)"/>
                          </v-col>

                          <v-col :cols="tamanhoCampos(item)" v-if="podeCancelar(item)">
                            <apuracao-contrato-cancelar
                              v-if="podeCancelar(item)"
                              :apuracao="item"
                              @ApuracaoContratoCancelar__cancelado="alterarStatusCancelado(item)">
                            </apuracao-contrato-cancelar>
                          </v-col>

                          <v-col :cols="tamanhoCampos(item)" v-if="item.jaIntegrado">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-row justify="center" align="center" v-on="on">
                                  <v-col cols="12" sm="12" class="pa-0" style="text-align: center;">
                                    <v-btn v-on="on" class="ma-0 pt-2" icon @click="(e) => imprimir(e, item)">
                                      <v-icon>print</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </template>
                              <div>
                                {{ $t('label.exportar') }} {{ $tc('label.nota_debito', 1) }}
                              </div>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  getMoney,
  getPercent,
  capitalize,
} from '../../common/functions/helpers';
import {
  listarApuracoesContratoNivel2,
  listarApuracoesContratoNivel2Simplificado,
} from '../../common/resources/apuracao/apuracao-contrato';
import PopoverLista from '../../shared-components/PopoverLista';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import ApuracaoContratoAcaoConcluir from './ApuracaoContratoAcaoConcluir';
import getBasePath from '../../common/functions/api-resource';
import exportacao from '../../common/resources/downloads';
import ApuracaoContratoAcaoConcluirIndustria from './finalizar-lote/ApuracaoContratoAcaoConcluirIndustria';
import ApuracaoAlterarDataFim from './ApuracaoAlterarDataFim';
import ApuracaoContratoCancelar from './ApuracaoContratoCancelar';

export default {
  name: 'ApuracaoContratoNivel2',
  components: {
    ApuracaoContratoCancelar,
    ApuracaoAlterarDataFim,
    ApuracaoContratoAcaoConcluirIndustria,
    ApuracaoContratoAcaoConcluir,
    PopoverLista,
  },
  data() {
    return {
      pesquisaCards: '',
      listaCards: [],
      filtrosAplicados: {},
      rowsPerPageItems: [12],
      pagination: {
        itemsPerPage: 12,
      },
      buscando: false,
      esperarDigitar: false,
      status: null,
      idContrato: null,
    };
  },
  watch: {
    filtroApuracaoContrato: {
      handler() {
        this.filtrosAplicados = this.filtroApuracaoContrato;
        this.buscar();
      },
    },
    pagination: {
      handler() {
        if (this.filtroApuracaoContrato) {
          this.buscar();
        }
      },
      deep: true,
    },
  },
  filters: {
    truncate(value, limit) {
      if (value.length > limit) {
        value = value.substring(0, (limit - 3)).concat('...');
      }
      return value;
    },
  },
  computed: {
    ...mapGetters('filtros', [
      'filtroApuracaoContratoNivel2',
      'filtroApuracaoContrato',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...generateComputed('APU_CONTR', [
      'canAccessCRUD',
    ]),
    ...generateComputed('ACESSO_CLIENTE', [
      'getAllRoles',
    ]),
    isVisaoContrato() {
      return !!this.$route.params.idContrato;
    },
    camposPopover() {
      const campos = [];
      if (this.isVisaoContrato) {
        campos.push({
          campo: 'descricao',
          label: this.$t('label.descricao_contrato'),
        });
      } else {
        campos.push({
          campo: 'descricao',
          label: this.$t('label.descricao_acao'),
        });
      }

      campos.push({
        campo: 'periodoApuracao',
        label: this.$tc('label.periodo_apuracao', 1),
        formatarCampos: capitalize,
      });

      campos.push({
        campo: 'codContrato',
        label: this.$t('label.cod_contrato'),
      });

      campos.push({
        campo: 'codAcao',
        label: this.$t('label.cod_acao'),
      });

      campos.push({
        campo: 'codApuracao',
        label: this.$t('label.cod_apuracao'),
      });

      campos.push({
        campo: 'status',
        label: this.$t('label.status'),
        formatarCampos: this.i18nStatus,
      });

      return campos;
    },
    isUsuarioFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    indAlterarDataFimApuracao() {
      return !!this.getAllRoles.filter((el) => el.startsWith('ALTERAR_DTA_FIM_APURACAO')).length
        && this.canAccessCRUD;
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroApuracaoContratoNivel2',
    ]),
    getMoney,
    getPercent,
    capitalize,
    alterarStatusCancelado(item) {
      item.status = 'CANCELADO';
    },
    tamanhoCampos(item) {
      let quant = 1;
      if (item.extensaoRecursiva) {
        quant += 1;
      }
      if (!item.semMeta) {
        quant += 1;
      }
      if (this.podeConcluir(item)) {
        quant += 1;
      }
      if (this.podeCancelar(item)) {
        quant += 1;
      }
      if (item.jaIntegrado) {
        quant += 1;
      }
      return 12 / quant;
    },
    alterarDataFim(item, data) {
      item.dataFim = data;
    },
    i18nStatus(status) {
      return this.$tc(`status_entidade.${status}`.toLowerCase(), 1);
    },
    alterarStatus(item, indAlterarStatus) {
      if (indAlterarStatus) {
        item.status = 'APROVADO';
      }
    },
    imprimir(e, item) {
      e.stopPropagation();

      const idApuracao = item.codApuracao;
      const basePath = getBasePath('job', 'recebimento/relatorio');
      const resource = exportacao(this.$http);

      resource.downloadGet(basePath, {
        param: `?nomeArquivo=${item.origemContrato || item.focoContrato}`,
        url: `apuracao/${idApuracao}/nota-debito/`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
    podeConcluir(item) {
      return (item.status === 'AGUARDANDO_APURACAO' || item.statusApuracao === 'AGUARDANDO_APURACAO')
        && (item.vlrConfApuracao > 0 || item.recebimento) && this.canAccessCRUD;
    },
    podeCancelar(item) {
      return (item.status === 'AGUARDANDO_APURACAO'
        || item.status === 'APURACAO_PREVIA')
        && this.canAccessCRUD
        && this.isUsuarioFuncionario;
    },
    dataFimEInicioIgual(dataFim, dataInicio) {
      return this.converterMesAno(dataFim) === this.converterMesAno(dataInicio);
    },
    converterMesAno(val) {
      this.moment.locale('pt-BR');
      const mesAno = this.moment(val).format('MMM/YY');
      return mesAno.charAt(0).toUpperCase() + mesAno.slice(1);
    },
    getTitulo(item) {
      if (this.idContrato) {
        return item.tipo;
      }
      return item.origemContrato || item.focoContrato;
    },
    getSubtitulo(item) {
      if (this.idContrato) {
        return `${item.codContrato} - ${item.codAcao} - ${item.codApuracao}`;
      }
      return `${item.codContrato} - ${item.codAcao} - ${item.codApuracao} :: ${item.tipo}`;
    },
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 1000);
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page || 1;
      const quantidatePorPagina = this.pagination.itemsPerPage || this.filtroApuracaoContratoNivel2.quantidatePorPagina;

      this.setFiltroApuracaoContratoNivel2({
        pagina,
        quantidatePorPagina,
      });
    },
    preencherFiltrosNivel2() {
      this.pagination.page = this.filtroApuracaoContratoNivel2.pagina;
      this.pagination.itemsPerPage = this.filtroApuracaoContratoNivel2.quantidatePorPagina;
    },
    selectItem(item) {
      this.consultar(listarApuracoesContratoNivel2Simplificado, (response) => {
        const listaSimplificada = response.data;
        const rota = this.isVisaoContrato ? 'detalharApuracaoContrato' : 'detalharApuracaoContratoStatus';

        const params = {
          id: item.codApuracao,
          idAcao: item.codAcao,
          apuracoes: listaSimplificada,
        };

        let query;

        if (this.status) {
          params.status = this.status;
        }

        if (this.idContrato || item.codContrato) {
          params.idContrato = this.idContrato || item.codContrato;
          query = { origemContrato: item.origemContrato || item.focoContrato };
        }

        this.$router.push({ name: rota, params, query });
      });
    },
    buscar() {
      this.consultar(listarApuracoesContratoNivel2,
        (response) => {
          this.totalPage = response.data.quantidadeRegistrosPagina;
          this.listaCards = response.data.resposta;
          this.rowsPerPageItems = [12, 24, 36, 48, 60, 72, 84];
          setTimeout(this.finalizandoBusca);
          this.preencherFiltrosNivel1Vuex();
        });
    },
    consultar(consulta, sucesso) {
      if (this.buscando) return;
      this.buscando = true;
      const params = {
        ...this.filtrosAplicados,
        filtro: this.pesquisaCards,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      if (this.status) {
        params.statusFiltroNivel = this.status;
      }

      if (this.idContrato) {
        params.codContratoFiltroNivel = this.idContrato;
      }

      consulta(params, this.$resource)
        .then(sucesso)
        .catch((err) => {
          this.finalizandoBusca();
          this.$error(this, err);
        });
    },
    finalizandoBusca() {
      this.buscando = false;
    },
    preencherFiltrosRouter() {
      this.status = this.$route.params.status;
      this.idContrato = this.$route.params.idContrato;
    },
  },
  beforeMount() {
    this.preencherFiltrosRouter();
  },
  mounted() {
    if (this.filtroApuracaoContratoNivel2) {
      this.preencherFiltrosNivel2();
    }
    if (this.filtroApuracaoContrato) {
      this.filtrosAplicados = this.filtroApuracaoContrato;
    }
    this.buscar();
  },
};
</script>

<style>
  .ApuracaoContratoNivel2__popover-lista {
    width: 100%;
  }
  .Barra_Pesquisa {
    width: 33%;
  }
  .clickable {
    cursor: pointer;
  }
  .Card_Apuracao {
    min-height: 146px !important;
    height: 100%;
  }
  .Card_Apuracao .Card_Title, .Card_Apuracao .Card_Content {
    color: #757575;
  }
</style>
<style src="../../assets/css/card-status.css" lang="css"></style>
